$S_2K: 1920px;
$S_1K: 1024px;

@mixin screen($screen){

    @if $screen == 1920 {
        @media (width > 1440px) {
            @content;
        }
    }

    @if $screen == 1440 {
        @media ((width > 1024px) and (width <= 1440px)) {
            @content;
        }
    }

    @if $screen == 768 {
        @media (width <= 1024px) {
            @content;
        }
    }

    @if $screen == 375 {
        @media (width < 768px) {
            @content;
        }
    }


    @if $screen == 1K {
        @media (width <= $S_1K) {
            @content;
        }
    }
 
    @if $screen == 2K {
        @media (width > $S_1K) {
            @content;
        } 
    }

    @if $screen == 4K {
        @media (width > $S_2K) {
            @content;
        } 
    }

}