$desktop-max-width: 1440px;
$desktop-width: 1024px;
$tablet-width: 768px;
$mobile-width: 375px;

@mixin devices($type) {
    .ef-box {
        margin: 0 auto;
        width: 100%;
        padding: 0 128px; // 所有媒体查询在ipad mini（1024 * 768）横屏下均不生效～！操～ 竖屏都正常！
    }

    // 1440+ 大屏电脑
    @if $type == pc {
        @media only screen and (width > $desktop-max-width) {
            .ef-box {
                max-width: 1200px;
                padding: 0 !important;
            }
            @content;
        }
    }

    // [1024
    @if $type == pc1024 {
        @media (width > $desktop-width) {
            @content;
        }
    }

    // [1024
    @if $type == pc1024equal {
        @media (width > $desktop-width) {
            @content;
        }
    }

    // (1024 ~ 1440] 笔记本
    @if $type == desktop {
        @media ((width > $desktop-width) and (width <= $desktop-max-width)) {
            .ef-box {
                padding: 0 128px !important;
            }
            @content;
        }
    }

    // [768 ~ 1024] pad
    @if $type == pad {
        @media (width <= $desktop-width) {
            .ef-box {
                padding: 0 48px !important;
            }
            @content;
        }
    }

    // [0 ~ 768) h5
    @if $type == h5 {
        @media (width < $tablet-width) {
            .ef-box {
                padding: 0 24px !important;
            }
            @content;
        }
    }

    // (1440 ~ ) only 1920
    @if $type == only1920 {
        @media only screen and (width > $desktop-max-width) {
            @content;
        }
    }

    // (1024 ~ 1440] only 1366
    @if $type == only1366 {
        @media ((width > $desktop-width) and (width <= $desktop-max-width)) {
            @content;
        }
    }

    // [768 ~ 1024] only 768
    @if $type == only768 {
        @media ((width <= $desktop-width) and (width >= $tablet-width)) {
            @content;
        }
    }
}

// 字体，针对 cn 站点 font-weight 需要手动设置一问题
@mixin font-definition($font-var, $font-weight) {
    font-family: var($font-var);
    html[site='tw'] &,
    html[site='cn'] & {
        font-weight: $font-weight;
    }
}
%ff-woff2-B {
    @include font-definition(--ff-woff2-B, 500);
}
%ff-woff2-M {
    @include font-definition(--ff-woff2-M, 400);
}
%ff-woff2-L {
    @include font-definition(--ff-woff2-EL, 300);
}
