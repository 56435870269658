
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.ef-img {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    text-align: center;

    .ef-img-item {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin: 0;
    }
    .ef-img-item-h5, .ef-img-item-pad {
        display: none;
    }
    @include devices(pad) {
        .ef-img-item-h5 {
            display: block;
        }
        .ef-img-item-pad + .ef-img-item-h5{
            display: none;
        }
        .ef-img-item-h5 + .ef-img-item-pc {
            display: none;
        }
    }
    @include devices(h5) {
        .ef-img-item-pad{
            display: none;
        }
        .ef-img-item-pad + .ef-img-item-h5{
            display: block;
        }
    }
}

// 设置了pad链接
.ef-img-has-pad {
    @include devices(pad) {
        .ef-img-item-h5, .ef-img-item-pad + .ef-img-item-pc {
            display: none;
        }
        .ef-img-item-pad {
            display: block;
        }
    }
    @include devices(h5) {
        .ef-img-item-h5 + .ef-img-item-pad, .ef-img-item-pad + .ef-img-item-pc {
            display: none;
        }
        .ef-img-item-h5 {
            display: block;
        }
    }
}
