
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
.lang_selector {
    position: relative;

    :global {
        .lang_item_current {
            display: flex;
            width: fit-content;
            align-items: center;
            margin-left: 39px;
            cursor: pointer;

            .lang_text {
                color: #03060b;
                font-size: 14px;
                line-height: 22px;
                padding-left: 5px;
                font-family: var(--font-family-Regular);
                font-weight: 400;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}