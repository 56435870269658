
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import '@elem/styles/devices.scss';

.header {
    :global {
        position: relative;
        z-index: 29;
        width: 100%;
        font-family: var(--ff-woff2-M);
        user-select: none;
        .header-content {
            max-width: 1200px;
            margin: 0 auto;
        }
        .header-dropdown-h5 {
            display: none;
        }
        @include devices(pc1024) {
            .is-open-search.header-inter {
                .header-nav-main,
                .header-tools {
                    display: none;
                }
            }
        }
        @include devices(pad) {
            height: 50px;
            background-color: #fff;
            .header-dropdown-h5 {
                display: block;
            }
            .header-inter {
                display: flex;
                flex-wrap: wrap;
            }
        }
    }
    &:global(.is-open-win) {
        :global {
            @include devices(pad) {
                height: 100vh;
                overflow-y: auto;
                .header-top {
                    display: flex;
                }
                .header-nav-main {
                    display: block;
                }
                .header-tools-item-control {
                    .navbar-control-bg {
                        display: none;
                    }
                    .navbar-control-item1 {
                        opacity: 1;
                        transform: translate(0px, 8px) rotate(45deg);
                    }
                    .navbar-control-item2 {
                        opacity: 0;
                    }
                    .navbar-control-item3 {
                        opacity: 1;
                        transform: translate(0px, -8px) rotate(-45deg);
                    }
                }
            }
        }
    }
}

.headernav {
    :global {
        width: 100%;
        background-color: #fff;
        box-sizing: border-box;
        @include devices(pc) {
            border-bottom: 1px solid #f5f7fa;
        }
        @include devices(desktop) {
            border-bottom: 1px solid #f5f7fa;
        }
        .header-content {
            display: flex;
            justify-content: space-between;
            height: 51px;
            @include devices(desktop) {
                margin-left: 128px;
                margin-right: 128px;
            }
            @include devices(pad) {
                .header-nav-l {
                    padding-left: 48px;
                    padding-right: 48px;
                    a {
                        display: flex;
                        height: 100%;
                        align-items: center;
                    }
                }
            }
            @include devices(h5) {
                .header-nav-l {
                    padding-left: 24px;
                    padding-right: 24px;
                }
            }
        }
        .header-nav-l {
            display: flex;
            align-items: center;
        }
        .header-nav-main {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: flex-end;
        }
        .header-nav-main-in {
            height: calc(100vh - 50px);
        }
        .header-nav-item-out {
            display: flex;
            height: 100%;
            align-items: center;
            color: #000;
            font-size: 14px;
            font-weight: 500;
            margin-right: 24px;
            font-family: var(--ff-woff2-B);
            transition: all 0.2s ease-out;
            cursor: pointer;
            &.none-child {
                .header-nav-item svg {
                    display: none;
                }
            }
            button {
                display: flex;
                align-items: center;
                cursor: pointer;
            }
            svg {
                margin-left: 8px;
                pointer-events: none;
                transition: all 0.2s ease-out;
            }
        }
        .header-nav-item {
            position: relative;
            height: 100%;
            font-family: var(--ff-woff2-M);
        }
        .header-nav-item-out.is-active {
            @include devices(pc) {
                .header-nav-item:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 2px;
                    background-color: #000;
                }
            }
            .header-nav-item svg {
                transform: rotate(180deg);
            }
            .header-dropdown-h5 .dropdown-content {
                display: block;
            }
        }
        @include devices(pad) {
            order: 1;
            .header-content {
                flex-flow: wrap;
                height: auto;
            }
            .header-nav-main {
                order: 3;
                flex-flow: wrap;
                width: 100%;
                display: none;
                .header-nav-item-out {
                    width: 100%;
                    height: auto;
                    display: block;
                    margin-right: 0;

                    &:last-child {
                        padding-bottom: 300px;
                    }
                }
                .header-nav-item {
                    width: 100%;
                    margin-right: 0;
                    padding: 20px 48px;
                    justify-content: space-between;
                    font-size: 16px;
                    font-family: var(--ff-woff2-B);
                }
            }

            .header-nav-l {
                order: 1;
                width: 50%;
                height: 50px;
            }
            .header-tools {
                order: 2;
                width: 50%;
                height: 50px;
                justify-content: flex-end;
                padding-right: 48px;
            }
        }
        @include devices(h5) {
            .header-nav-main {
                .header-nav-item {
                    padding: 20px 24px;
                }
            }
            .header-tools {
                padding-right: 24px;
            }
        }
    }
}

.headertools {
    :global {
        display: flex;
        align-items: center;
        .header-tools-item {
            position: relative;
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-left: 16px;
            line-height: 0;
            button {
                cursor: pointer;
            }
            &:not(.header-tools-item-user):hover {
                opacity: 0.7;
            }
            &.header-tools-item-user:hover > svg {
                opacity: 0.7;
            }
            &.header-tools-item-user.header-tools-item-user-open .header-userDialog {
                display: block;
            }
            &.store-icon {
                @include devices(pc1024) {
                    html[site='cn'] & {
                        .storeBox_pc {
                            display: none;
                        }
                        &:hover {
                            .storeBox_pc {
                                display: block;
                            }
                        }
                    }
                }
                html[site='cn'] & {
                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
        .header-tools-item:first-child {
            margin-left: 0;
        }
        .header-tools-item-control {
            display: none;
        }
        @include devices(pad) {
            .header-tools-item-control {
                display: flex;
            }
        }
    }
}

.dropdownpc {
    :global {
        position: absolute;
        left: 0;
        top: 51px;
        width: 100%;
        background-color: #fff;

        .mask {
            position: absolute;
            left: 0;
            height: 100vh;
            width: 100%;
            background-color: rgba($color: #000000, $alpha: 0.5);
        }

        .dropdown-pc-inter {
            max-height: calc(100vh - 152px);
            overflow-y: auto;

            @include devices(pad) {
                max-height: 100vh;
            }
        }
        .dropdown-content {
            max-width: 1200px;
            margin: 0 auto;
            @include devices(desktop) {
                margin-left: 128px;
                margin-right: 128px;
            }
            .dropdown-inter {
                overflow: hidden;
            }
        }
        .listcarda-c {
            position: relative;
            width: 100%;
            cursor: pointer;
            overflow-x: auto;
            &::-webkit-scrollbar {
                display: none;
            }

            .scroll-content {
                display: flex;
                width: 868px;
            }
        }
        @include devices(pad) {
            display: none;
        }
    }
    &:global(.is-search) {
        :global {
            top: 0;
            display: block;
            background-color: transparent;
            @include devices(pad) {
                background-color: transparent;
                .dropdown-content {
                    padding: 0;
                    .dropdown-inter {
                        margin-left: 0;
                        margin-right: 0;
                        border-radius: 0;
                    }
                    .searchbox-h {
                        background-color: #fff;
                    }
                }
            }
        }
    }
}

.dropdownh5 {
    :global {
        position: relative;
        display: none;
        background-color: #fff;
        .dropdown-content {
            display: none;
            margin: 0 auto;
            background-color: #f4f4f4;
        }
        .dropdown-inter {
            padding: 0;
        }
    }
}

.dropboxapc {
    padding: 17px 0 0;
    :global {
        position: relative;
        display: flex;
        align-items: flex-start;
        .dropboxa-l {
            min-width: 180px;
            width: 23.5%;
            margin-right: 50px;
            @media (width <= 1440px) {
                margin-right: 32px;
            }
            .dropboxa-l-h {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 15px 0;
                cursor: pointer;
                font-size: 14px;
                color: #000;
                font-family: var(--ff-woff2-B);
                &:hover {
                    opacity: 0.7;
                }
                svg {
                    color: inherit;
                    transform: rotate(0);
                    transition: all 0.2s ease-out;
                }
            }
            .dropboxa-l-item {
                .dropboxa-sub {
                    display: none;
                }
                .selected {
                    padding-left: 20px;
                    margin-left: -20px;
                    background: #f4f4f4;
                }
            }
            .dropboxa-l-item.is-open {
                .dropboxa-sub {
                    display: block;
                }
                .dropboxa-l-h svg {
                    transform: rotate(180deg);
                }
            }
        }
        .dropboxa-sub {
            li {
                display: flex;
                align-items: center;
                cursor: pointer;
                padding: 16px 0 16px 20px;
                &:hover {
                    opacity: 0.85;
                }
            }
            li.is-active {
                background: #f4f4f4;
            }
        }
        .dropboxa-r {
            height: 583px;
            flex: 1;
            overflow: hidden;
        }
    }
}

.dropboxah5 {
    :global {
        position: relative;
        .dropboxa-h {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            padding: 16px 48px 16px 58px;
            background-color: #fff;
            font-family: var(--ff-woff2-M);
            position: sticky;
            top: 0;
            background: #fff;
            z-index: 100;
            @include devices(h5) {
                padding: 16px 24px 16px 34px;
            }
            svg {
                transition: all 0.2s ease-out;
            }
        }
        .dropboxa-sub {
            padding: 0 48px 0 58px;
            background-color: #f4f4f4;
            display: none;
            @include devices(h5) {
                padding: 0 24px 0 34px;
            }

            .hide-title {
                margin-top: 5px;
                .list-card-a-h5 {
                    margin-bottom: 0;
                    .listcarda-h {
                        display: none;
                    }
                    .listcarda-c {
                        margin-top: 0;
                    }
                }
            }
            .dropboxa-sub-item:not(:first-child) {
                border-top: 1px solid #d9d9d9;
                padding-top: 24px;
            }
            .dropboxa-sub-item:last-child {
                padding-bottom: 1px;
            }
        }
        .dropboxa-item.is-active {
            .dropboxa-h svg {
                transform: rotate(180deg);
            }
            .dropboxa-sub {
                display: block;
            }
        }
        .dropboxa-accessories-link {
            display: block !important;
            text-align: right;
            line-height: 18px;
            padding: 24px 0 24px;
            a {
                padding: unset !important;
                margin: unset !important;
                display: unset !important;
                color: #3f68e0;
                vertical-align: middle;
                font-family: var(--ff-woff2-M);
            }
            svg {
                transform: rotate(-90deg);
                vertical-align: middle;
            }
        }
    }
}

.dropboxb {
    :global {
        position: relative;
        display: flex;
        align-items: flex-start;
        padding: 26px 0 50px;
        .dropboxb-l {
            min-width: 180px;
            width: 23.5%;
        }
        .dropboxb-l-h {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 14px 0 14px 20px;
            font-family: var(--ff-woff2-M);
            color: #000;
            cursor: pointer;
            @include devices(pad) {
                padding-left: 58px;
                color: #444;
            }
            @include devices(h5) {
                padding-left: 34px;
                color: #444;
            }
        }
        li.is-active {
            .dropboxb-l-h {
                background-color: #f4f4f4;
            }
        }
        .dropboxb-r {
            overflow-x: auto;
            .scroll {
                flex: 1;
                margin-left: 50px;
                display: flex;
                flex-wrap: wrap;

                @media (width <= 1440px) {
                    flex-wrap: unset;
                    width: max-content;
                    margin-left: 32px;
                }
            }
        }
        .dropboxb-r-item {
            position: relative;
            display: block;
            width: 278px;
            height: 162px;
            border-radius: 4px;
            overflow: hidden;
            margin-right: 10px;
            margin-bottom: 10px;
            background-color: #999;
            &:first-child {
                margin-left: 0;
            }
            img {
                transition: all 0.2s ease-in;
            }
            &:hover {
                img {
                    transform: scale(1.1);
                }
            }
            .mask {
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                background: #000;
                opacity: 0.3;
            }
            .listcardb-msg {
                position: absolute;
                left: 0;
                bottom: 14px;
                width: 100%;
                padding: 0 15px;
                color: #fff;
            }
            .listcardb-title {
                white-space: nowrap;
                font-size: 14px;
                font-weight: 500;
                font-family: var(--ff-woff2-B);
            }
            .listcardb-desc {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                font-size: 12px;
                font-weight: 400;
                margin-top: 4px;
                line-height: 13px;
                white-space: pre-line;
                // word-break: break-all;
                font-family: var(--ff-woff2-M);
            }
        }
    }
    &:global(.dropboxb-h5) {
        :global {
            width: 100%;
            .dropboxb-l {
                width: 100%;
                padding-bottom: 20px;
            }
            .dropboxb-list {
                padding: 0 20px;
                overflow: hidden;
                @include devices(pad) {
                    padding: 0 0 0 58px;
                }
                @include devices(h5) {
                    padding: 0 0 0 34px;
                }
            }
            .dropboxb-list-in {
                display: flex;
                overflow: auto;
            }
            .dropboxb-r-item {
                flex-shrink: 0;
                width: 307px;
                height: 180px;
                margin: 0 10px 0 0;
            }
        }
    }
}

.dropboxc {
    padding: 26px 0 50px;
    :global {
        .dropboxc-inter {
            display: flex;
            justify-content: center;
        }
        .dropboxc-item {
            margin: 0 45px;
        }
        .dropboxc-h {
            font-size: 14px;
            color: #757575;
        }
        .dropboxc-sub {
            margin-top: 16px;
            a {
                display: block;
                padding: 6px 0;
                line-height: 20px;
                color: #000;
                font-size: 14px;
                font-weight: 400;
                &:hover {
                    font-weight: 500;
                    text-decoration: underline;
                }
            }
        }
        @include devices(pad) {
            .dropboxc-inter {
                flex-wrap: wrap;
            }
            .dropboxc-h {
                padding-top: 14px;
                line-height: 17px;
                font-family: var(--ff-woff2-B);
                color: #757575;
                padding-bottom: 14px;
            }
            .dropboxc-item {
                width: 100%;
                margin: 0 48px 10px;
                padding-bottom: 12px;
                border-bottom: 1px solid #ddd;
                &:last-child {
                    border-bottom: none;
                    margin-bottom: 0;
                }
            }
            .dropboxc-sub {
                margin-top: 0;
                a {
                    font-family: var(--ff-woff2-M);
                    font-weight: 400;
                    padding: 16px 0;
                    line-height: 20px;
                    font-size: 16px;
                }
            }
        }
        @include devices(h5) {
            .dropboxc-item {
                margin: 0 35px 10px;
            }
        }
    }
}

.dropboxd {
    padding: 26px 0 50px;
    :global {
        .dropboxd-inter {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
        }
        .dropboxd-item {
            position: relative;
            width: 280px;
            height: 164px;
            margin: 0 10px 20px;
            border-radius: 4px;
            overflow: hidden;
            img {
                transition: all 0.2s ease-out;
            }
            &:hover {
                img {
                    transform: scale(1.1);
                }
            }
        }
        .mask {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background: #000;
            opacity: 0.3;
        }
        .dropboxd-msg {
            position: absolute;
            bottom: 16px;
            left: 0;
            width: 100%;
            color: #fff;
            .dropboxd-title {
                margin: 0 15px;
                font-size: 14px;
                font-weight: 500;
                font-family: var(--ff-woff2-B);
            }
            .dropboxd-subtitle {
                margin: 0 15px;
                font-size: 12px;
                word-wrap: break-word;
                font-family: var(--ff-woff2-M);
            }
        }
        @media (width <= 1024px) {
            overflow-x: auto;
            .dropboxd-inter {
                flex-wrap: unset;
                padding: 44px 0 44px 48px;
                width: max-content;
                @include devices(h5) {
                    padding: 44px 0 44px 34px;
                }

                .dropboxd-item {
                    width: 308px;
                    height: 180px;
                    margin: 0 10px 0 0;
                }
            }
        }
    }
}

.dropuser {
    :global {
        position: absolute;
        left: 0;
        width: 100%;
        padding: 0 80px;
        display: none;

        .dropuser-inter {
            max-width: 1280px;
            margin: 0 auto;
            text-align: right;
        }
        .user-item-box {
            display: inline-block;
            vertical-align: top;
            width: 200px;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            background-color: #fff;
        }
        .user-item {
            line-height: 36px;
            text-align: left;
            padding: 0 20px;
            cursor: pointer;
            .arrow-right {
                display: none;
            }
        }
        @include devices(pad) {
            position: static;
            order: 9;
            padding: 30px 20px 10px;
            display: none;
            .dropuser-inter {
                max-width: 100%;
                text-align: center;
                .user-item-box {
                    display: block;
                    width: 100%;
                }
                .user-item {
                    text-align: center;
                    .arrow-right {
                        display: inline-block;
                        margin-left: 8px;
                    }
                }
                .user-item-login {
                    height: 44px;
                    line-height: 44px;
                    background-color: #2673ff;
                    border-radius: 8px;
                    a {
                        color: #fff;
                    }
                }
            }
        }
    }
    &:global(.is-pc-win) {
        :global {
            display: block;
            @include devices(pad) {
                display: none;
            }
        }
    }
}

.searchbox {
    :global {
        margin-left: 200px;
        margin-right: 200px;
        background-color: #fff;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        border-radius: 0px 0px 8px 8px;
        overflow: hidden;
        .searchbox-h {
            position: relative;
            background-color: #f4f4f4;
            height: 52px;
            .searchbox-input {
                width: 100%;
                padding-left: 54px;
                height: 52px;
                background: transparent;
                font-family: var(--ff-woff2-M);
                &::placeholder {
                    color: #afafaf;
                }
                html[site='sa'] & {
                    padding-left: unset;
                    padding-right: 54px;
                }
            }
            .searchbox-icon {
                position: absolute;
                top: 50%;
                left: 18px;
                transform: translateY(-50%);
                display: flex;
                align-items: center;
                font-size: 24px;
                html[site='sa'] & {
                    left: unset;
                    right: 18px;
                }
            }
            .searchbox-del {
                position: absolute;
                display: flex;
                top: 50%;
                right: 18px;
                align-items: center;
                font-size: 18px;
                transform: translateY(-50%);
                cursor: pointer;
                html[site='sa'] & {
                    right: unset;
                    left: 18px;
                }
            }
        }
        .searchbox-c-h {
            font-size: 14px;
            line-height: 24px;
            padding: 10px 24px;
            color: #707070;
        }
        .searchbox-c-list {
            max-height: 250px;
            overflow-y: auto;
            .searchbox-c-item {
                display: flex;
                width: 100%;
                align-items: center;
                padding: 10px 24px;
                line-height: 24px;
                cursor: pointer;
                font-family: var(--ff-woff2-M);
                &:hover {
                    background-color: #f4f4f4;
                }
            }
            .ef-picture {
                width: 60px;
                height: 60px;
            }
        }
        @include devices(pad) {
            height: 100vh;
            .searchbox-h {
                margin: 0 24px;
                position: relative;
                @include devices(h5) {
                    margin: 0;
                }
            }
            .searchbox-c {
                padding-left: 24px;
                @include devices(h5) {
                    padding-left: 0;
                }
                .searchbox-c-list {
                    max-height: calc(100vh - 96px);
                    .searchbox-c-item:last-child {
                        margin-bottom: 300px;
                    }
                }
            }
        }
    }
}

.tooltoggle {
    :global {
        position: relative;
        display: inline-block;
        width: 24px;
        .navbar-control-item {
            position: relative;
            display: block;
            width: 17px;
            height: 8px;
            margin: 0 auto;
            transition: transform 0.5s;
            opacity: 0;
            &:after {
                content: '';
                position: absolute;
                top: 3px;
                left: 0;
                width: 17px;
                height: 2px;
                background-color: #000;
            }
        }
        .navbar-control-bg {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

.popover {
    padding: 20px 14px 12px;
    background-color: #fff;
    :global {
        .desc-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 4px;
            svg {
                width: 16px;
                min-width: 16px;
            }
            .text {
                flex: 1;
                color: #000;
                font-family: var(--ff-woff2-EM);
                font-size: 12px;
                line-height: 1.2;
                text-align: left;
            }
        }
        .button {
            width: 100%;
            margin: 12px auto 10px;
            padding: 10px;
            line-height: 18px;
            border-radius: 2px;
            background: #000;
            color: #fff;
            font-family: var(--ff-woff2-EM);
            font-size: 12px;
            cursor: pointer;
            &:hover {
                opacity: 0.92;
            }
        }
        .tip {
            line-height: 18px;
            text-align: center;
            font-family: var(--ff-woff2-EM);
            color: #707070;
            font-size: 12px;
            white-space: pre-wrap;

            .text-link {
                text-decoration: underline;
                color: #000;
                html[site='es'],
                html[site='fr'] & {
                    display: flex;
                    justify-content: center;
                }
            }
        }
        .close-buuton {
            position: absolute;
            top: 6px;
            right: 6px;
            cursor: pointer;
        }
    }
}
.popover_tooltip {
    :global {
        .ant-tooltip-inner {
            background-color: #fff;
        }
    }
}

.userDialog {
    :global {
        position: absolute;
        top: 24px;
        left: auto;
        right: -24px;
        z-index: 3;
        margin: 0;
        padding: 12px 24px;
        border: none;
        outline: none;
        opacity: 1;
        background-color: transparent;
        .header-userDialog-in {
            position: relative;
            width: 250px;
            padding: 6px 0;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
        }
        @include devices(pad) {
            display: none !important;
        }
    }
}
