
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';


.content {
    :global {
        display: block;
        position: relative;
        width: 100%;
        .editor-glo-bar-in {
            .glo-bar-type-img-text {
                .glo-bar-text-box {
                    text-align: left;
                }
            }
            .glo-bar-type-img-text-btn {
                .glo-bar-text-box {
                    text-align: left;
                }
                &.glo-bar-type-img-text-btn-link {
                    .glo-bar-btn {
                        background-color: transparent !important;
                        text-decoration: underline;
                        color: #fff;
                    }
                }
            }
        }
        .swiper .swiper-button-prev::after {
            margin-left: 2px;
        }
        .swiper .swiper-button-next::after {
            margin-left: -2px;
        }
        .swiper .swiper-button-prev::after, .swiper .swiper-button-next::after {
            border-width: 1px;
            border-color: #fff;
        }
        @include devices(pad) {
            .editor-glo-bar-in {
                .glo-bar-type-img-text.glo-bar-only-bgimg {
                    .glo-bar-text-box {
                        padding-right: 60px;
                    }
                }
                .glo-bar-type-img-text-btn {
                    .glo-bar-text-box {
                        padding-right: 60px;
                    }
                }
            }
        }
        @include devices(h5) {
            .editor-glo-bar-in {
                .glo-bar-type-img-text-btn {
                    .glo-bar-text-box-l {
                        flex: 1;
                    }
                    .glo-bar-title {
                        word-break: break-all;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        overflow: hidden;
                    }
                }
            }
        }
    }
    
    &:global(.editor-glo-bar-hide) {
        :global {
            display: none !important;
        }
    }
}
