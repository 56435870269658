
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import '@elem/styles/screen.scss';

.pdf-activity{
    :global{
        position: relative;
        width: 100%;
        height: 120px;
        
        .ef-link{
            display: block;
            width: 100%;
            height: 100%;
        }

        @include screen(375){
            height: 60px;
        }
    }

    &:global(.activity-bar){
        :global{
            position: sticky;
            top: 0;
            z-index: 1050;
            cursor: pointer;
        }
    }
}