
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';


.ef-bundles-calculator {
    position: relative;
    display: flex;
    align-items: center;
    width: max-content;
    background-color: #E1E1E1;
    height: 43px;
    padding: 2px 0;
    border-radius: 4px;
    .ef-bundles-calculator-red, .ef-bundles-calculator-add {
        display: flex;
        width: 45px;
        height: 100%;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &.ef-bundles-calculator-disabled {
            cursor: default;
        }
    }
    .ef-bundles-calculator-num {
        flex: 1;
        height: 100%;
        min-width: 60px;
        text-align: center;
    }
    .ef-bundles-calculator-icon {
        position: relative;
        display: flex;
        width: 16px;
        height: 16px;
        &::before {
            content: '';
            position: absolute;
            top: 7px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: #000;
        }
    }
    .ef-bundles-calculator-add {
        .ef-bundles-calculator-icon::after {
            content: '';
            position: absolute;
            top: 7px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: #000;
            transform: rotate(90deg);
        }
    }
    @include devices(desktop) {
    }
    @include devices(pad) {
    }
    @include devices(h5) {
    }
}
