
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.ef-check-in-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    background-color: transparent;
}
.ef-check-in-dialog::backdrop {
    background-color: rgba(0, 0, 0, 0.7);
}

.ef-check-in-dialog-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    
}

.ef-check-in-dialog-body {
    position: relative;
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    background-color: #fff;
    padding: 32px;
    border-radius: 12px;
    @include devices(pad) {
        max-width: 320px;
    }
}
// 关闭
.ef-check-in-dialog-close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    svg {
        vertical-align: top;
    }
}
// 标题
.ef-check-in-dialog-title {
    font-family: var(--ff-woff2-B);
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #000;
}
// 按钮
.ef-check-in-dialog .ef-check-in-dialog-btn {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: #000;
    border-radius: 28px;
    height: 48px;
    font-family: var(--ff-woff2-B);
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    &:hover {
        color: #fff;
    }
}


.ef-check-in-dialog-footer {
    margin-top: 32px;
}