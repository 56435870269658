
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import '@elem/styles/devices.scss';

// 2024/07/10 jocelyn https://project.feishu.cn/mz820/story/detail/4424751376
.TopBarCountdown {
    :global{
        position: relative;
        display: block;
        text-decoration: none;
        background-image: url('https://ecoflow-service-us-cdn.ecoflow.com/upload/image/1719562395066/_PC.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50%;
        .TopBarCountdown-in {
            display: flex;
            justify-content: space-between;
            max-width: 1456px;
            padding: 0 128px;
            height: 60px;
            margin: 0 auto;
            align-items: center;
        }
        .TopBarCountdown-title {
            font-family: var(--ff-woff2-B);
            font-size: 14px;
            line-height: normal;
            color: #F7A40A;
        }
        .TopBarCountdown-sub_title {
            font-family: var(--ff-woff2-B);
            font-size: 18px;
            line-height: normal;
            color: #000;
        }
        .TopBarCountdown-r {
            display: flex;
            align-items: center;
            gap: 10px;
            color: #fff;
            font-family: var(--ff-woff2-M);
            font-size: 14px;
        }
        .TopBarCountdown-text1 {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            border-radius: 4px;
            background-color: #fff;
            font-size: 14px;
            color: #000;
        } 
        .TopBarCountdown-text2 {
            font-size: 14px;
        }
        .TopBarCountdown-front_tips {
            display: flex;
        }
        .TopBarCountdown-front_tips_h5 {
            display: none;
        }
        @include devices(pad){
            background-image: url('https://ecoflow-service-us-cdn.ecoflow.com/upload/image/1719562423424/_M.jpg');
            .TopBarCountdown-in {
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                padding: 0 48px;
            }
            .TopBarCountdown-title {
                display: none !important;
            }
            .TopBarCountdown-sub_title {
                font-size: 12px;
            }
            .TopBarCountdown-r {
                color: #000;
                gap: 5px;
                font-size: 12px;
            }
            .TopBarCountdown-front_tips_pc {
                width: 32px;
            }
            .TopBarCountdown-text2 {
                font-size: 12px;
            }
            .TopBarCountdown-text1 {
                width: 24px;
                height: 24px;
                font-size: 12px;
                background-color: #000;
                color: #fff;
            }
        }

        @include devices(h5) {
            .TopBarCountdown-in {
                padding: 0 24px;
            }
            .TopBarCountdown-front_tips_pc {
                display: none;
            }
            .TopBarCountdown-front_tips_h5 {
                display: block;
                width: 24px;
            }
        }
    }
}