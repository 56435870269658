@mixin ef-font($type: Regular, $isNumber: false) {
    // 最粗
    @if $type == ExtraBold {
        font-family: var(--font-family-ExtraBold);
        @if $isNumber {
            font-weight: 700;
        }
    }
    // 第二粗
    @else if $type == Bold {
        font-family: var(--font-family-Bold);
        @if $isNumber {
            font-weight: 600;
        }
        html[site='cn'] & {
            font-weight: 500;
        }
    }
    // 半粗
    @else if $type == SemiBold {
        font-family: var(--font-family-SemiBold);
        @if $isNumber {
            font-weight: 500;
        }
        html[site='cn'] & {
            font-weight: 500;
        }
    }
    // 中粗
    @else if $type == Medium {
        font-family: var(--font-family-Medium);
        @if $isNumber {
            font-weight: 500;
        }
        html[site='cn'] & {
            font-weight: 400;
        }
    }
    // 默认
    @else if $type == Regular {
        font-family: var(--font-family-Regular);
        html[site='cn'] & {
            font-weight: 400;
        }
    }
    // 偏细
    @else if $type == ExtraLight {
        font-family: var(--font-family-ExtraLight);
    }
    // 最细
    @else if $type == Light {
        font-family: var(--font-family-Light);
    } @else {
        @error "字号类型错误 #{$type}.";
    }
}
